import { assertString } from '@sortlist-frontend/utils';

import { Base64Codec } from '../base64/base64codec';

export type JwtPayload = {
  iss?: string;
  sub?: string;
  aud?: string;
  exp: number;
  [k: string]: string | number | undefined;
};

export class JwtParser {
  /**
   * Minimal check to see if a token string have at least 3 non empty parts
   */
  static isTokenSyntaxValid(token: string): boolean {
    try {
      JwtParser.extractJwtParts(token);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * @throws {Error} if token payload is empty or token not a string
   */
  static extractJwtParts(token: string) {
    const errorPrefix = 'Cannot extract JWT parts';
    assertString(token, `${errorPrefix}: token must be a string`);
    const parts = token.split('.').filter((part) => part.length > 1);
    if (parts.length !== 3) {
      throw new Error(`${errorPrefix}: must have 3 elements, received ${parts.length}`);
    }
    const [header, payload, signature] = parts;
    return {
      header,
      payload,
      signature,
    };
  }

  /**
   * @throws {Error} if token payload is empty or token not a string
   * @throws {SyntaxError} if token cannot be parsed (invalid json)
   */
  static getPayload(token: string): JwtPayload {
    const { payload } = JwtParser.extractJwtParts(token);
    return JSON.parse(Base64Codec.decode(payload));
  }
}
